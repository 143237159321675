import { useState } from "react";
import { toast } from "react-toastify";

const QuizSubjective = ({name, value, handleChange, disabled}) => {
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isExceeded, setIsExceeded] = useState(false);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 300) {
      setIsExceeded(false);
      setInputValue(newValue);
      handleChange(e); // 부모 컴포넌트로 입력 값 전달
    } else {
      setIsExceeded(true);
      toast.error("Please limit your input to 300 characters.");
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      <div className="mt-7 flex flex-col">
        <div>Please enter your answer</div>
        <textarea
          className="quiz-textarea"
          name={name}
          value={value}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          rows="6"
          placeholder={
            value.length === 0
              ? "Please enter your answer within 200~300"
              : ""
          }
          disabled={disabled}
        />
        <div className="char-count self-end">
          Character Count: {value.length}/300
        </div>
      </div>

      <style>{`
        .quiz-textarea {
          width: 100%;
          height: 150px;
          padding: 10px;
          margin-top: 10px;
          font-size: 16px;
          border: 2px solid #ccc;
          border-radius: 4px;
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
          transition: border-color 0.3s, box-shadow 0.3s;
          background-color: #f8f9fa; /* 기본 배경색 */
        }

        .quiz-textarea:focus {
          border-color: #007bff;
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
          outline: none;
          background-color: #ffffff;
        }

        .char-count {
          margin-top: 15px;
          font-size: 14px;
          color: #333;
        }
      `}</style>
    </>
  );
};

export default QuizSubjective;
