import React from 'react';
import Wrapper from "../assets/wrappers/StatsContainer";
import PlayerStatItem from '../components/PlayerStatItem';
import { toast } from 'react-toastify';

const StatPlayerContent = ({ playerState, sortKey }) => {
    const filterItems = (items, key) => {
        switch (key) {
            case 'all':
                return items;
            case 'correct':
                return items.filter(item => item.status === 'correct');
            case 'wrong':
                return items.filter(item => item.status === 'wrong');
            case 'isNotSubmitted':
                return items.filter(item => item.status === 'isNotSubmitted');
            default:
                return items;
        }
    };

    const filteredItems = filterItems(playerState, sortKey);

    return (
        <>
            <Wrapper>
            {filteredItems.length > 0 ? (
                    filteredItems.map((item, index) => (
                        <PlayerStatItem key={index} item={item} />
                    ))
                ) : (
                    <div className="flex justify-center items-center text-gray-500 text-lg py-4">
                        No data available.
                    </div>
                )}
            </Wrapper>
        </>
    );
};

export default StatPlayerContent;