const QuizRadioRow = ({
    type,
    name,
    quizNum,
    choice,
    handleChange,
    checked,
    disabled
  }) => {
    const key = Object.keys(choice)[0]
    return (
      <label className="form-label">
        <input
          type={type}
          className="form-radio"
          value={key}
          checked={checked === key}
          onChange={handleChange}
          disabled={disabled}
        />
        &nbsp; &nbsp;
        {`${key}`}
      </label>
    );
  };
  
  export default QuizRadioRow;
  