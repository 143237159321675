import Wrapper from "../assets/wrappers/PlayerItem";

const PlayerStatItem = ({ item }) => {
  return (
    <Wrapper color={"#1d4ed8"}>
      <div className="parent">
        <div className="name">Question {item.quizNum}</div>
        {item.status === "isNotSubmitted" ? (
          <span className=" text-yellow-400 unanswered">Unanswered</span>
        ) : (
          <div
            className={`${item.status === "correct" ? "correct" : "wrong"} answer-wrapper`}
          >
            Answered:
            {item.submittedAnswer.map((answer, index) => {
              return (
                <span key={index} className="answer-text">
                  {`${index >= 1 ? "-" : ""}${answer}`}
                </span>
              );
            })}
          </div>
        )}
      </div>
    </Wrapper>
  );
};
export default PlayerStatItem;
