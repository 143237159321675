import { useEffect, useState } from "react";
import { StatsContainer } from "../../components";
import customFetch from "../../utils/axios";
import StatSearch from "../../components/StatSearch";
import StatPagination from "../../components/StatPagination";
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchData,
  setPage,
  setPageSize,
  setSortKey,
  setSortOrder,
  setSearchQuery,
} from '../../features/stat/statSlice';




const Stats = () => {

  const dispatch = useDispatch();
  const { page, pageSize, sortKey, sortOrder, searchQuery } = useSelector((state) => state.stat);

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch, page, pageSize, sortKey, sortOrder, searchQuery]);


  return (
    <>
      <StatSearch/>
      <StatsContainer />
      <StatPagination />
    </>
  );
};
export default Stats;
