import Quiestions_New from "../assets/quizzes/Quiestions_New";

const QuizQuestion = ({ quizNum }) => {
  const questions = { ...Quiestions_New };
  const question = questions[parseInt(quizNum) - 1];
  const choices = questions[parseInt(quizNum) - 1].choices;

  return (
    <>
      <h4>Quiz {quizNum}</h4>
      <p>
        {question.quizType === "sequence" ? (
          <>
            <div>
              {question.question}
              
            </div>
            <div className="mt-3 flex flex-col items-center">
              <div className="font-bold"> Rank the following 5 vectors in order of importance:</div>
              {`[${choices[0].A} / ${choices[1].B} / ${choices[2].C} / ${choices[3].D} / ${choices[4].E}]`}
            </div>
          </>
        ) : (
          questions[parseInt(quizNum) - 1].question
        )}
      </p>
    </>
  );
};

export default QuizQuestion;
