import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = { name: "", age: 0, email: "" }

// createSlice 함수를 이용하여 slice를 생성한다. slice란 reducer와 action을 한 파일에 작성할 수 있도록 해주는 함수이다.
export const testUserSlice = createSlice({
    name: "testUser",
    initialState: { value: initialStateValue },
    reducers: {
        login: (state, action) => {
            state.value = action.payload
        },
        logout: (state) => {
            state.value = initialStateValue
        }
    },
});

export const { login, logout } = testUserSlice.actions; // action을 다른 파일에서 사용할 수 있도록 export
export default testUserSlice.reducer; // reducer를 다른 파일에서 사용할 수 있도록 export, reducer란 state를 변경하는 함수이다.