import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { Error, Administer, Landing, Denied, ProtectedAdmin, ProtectedPlayer, Submitted } from "./pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AllTeams, SharedLayout, Stats } from "./pages/dashboard";
import Quiz from "./pages/quiz/Quiz";
import SubmitPage from "./pages/SubmitPage";
import Test from "./pages/Test";
import Login from "./pages/Login";
import RoleBasedRedirect from "./pages/RoleBasedRedirect";
import PlayerPage from "./pages/PlayerPage";
import PlayerLayout from "./pages/dashboard/PlayerLayout";
import StatPlayer from "./pages/dashboard/StatPlayer";


function App() {

  // 배포 환경에서 console.log, console.warn 지우기
  if (process.env.NODE_ENV === "production") {
    console = window.console || {};
    console.log = function no_console() { };
    console.warn = function no_console() { };
    console.error = function () { };
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RoleBasedRedirect />}>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="stats" element={<ProtectedAdmin><SharedLayout><Stats /></SharedLayout></ProtectedAdmin>} />
          <Route path="statplayer/:registNum" element={<ProtectedAdmin><SharedLayout><StatPlayer /></SharedLayout></ProtectedAdmin>} />
          
          <Route path="playerpage" element={<ProtectedPlayer><PlayerLayout></PlayerLayout></ProtectedPlayer>} />
          <Route path="quiz/:quizNum" element={<Quiz />} />
          <Route path="submitpage" element={<SubmitPage />} />

        </Route>

        <Route path="login" element={<Login />} />

        
        <Route path="denied" element={<Denied />} />
        <Route path="*" element={<Navigate to="/login" />} />

        <Route path="all-teams" element={<AllTeams />} />
        

        {/* <Route path="admin" element={<Administer />} />
        <Route path="landing/:quizNum" element={<Landing />} />
        <Route path="all-teams" element={<AllTeams />} />
        <Route path="test" element={<Test />} />
        <Route path="submitted" element={<Submitted />} /> */}

      </Routes>
      <ToastContainer position="top-center" />
    </BrowserRouter>
  );
}

export default App;
