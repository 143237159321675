import Wrapper from "../assets/wrappers/PlayerItem";

const PlayerItem = ({ item }) => {

  return (
    <Wrapper color={"#1d4ed8"}>
      <div className="parent">
        <div className="name">Question {item.quizNum}</div>
        {
          item.status === "isNotSubmitted" ? 
          (<span className="wrong">Unanswered</span>) : 
          (<div className="correct answer-wrapper">
            Answered: {
            item.submittedAnswer.map((answer, index) => {
              return (
                <span key={index} className="answer-text">
                  {`${index >= 1 ?"-": ""}${answer}`}
                </span>
              );
            })
          }</div>)


        }

      </div>
      {/* <hr /> */}
      {/* <header>
        <span className="count">Total: {totalAnswers}</span>
        <span className="correct">Correct: {correctAnswers}</span>
        <span className="wrong">Wrong: {wrongAnswers}</span>
      </header> */}
    </Wrapper>
  );
};
export default PlayerItem;
