import { NavLink } from 'react-router-dom';
import { MdQueryStats } from "react-icons/md";
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';


const NavLinks = () => {
  const { player } = useSelector((store) => store.player);

  const [menu, setMenu] = useState("");



  useEffect(() => {
    if (player) {
      if (player.isAdmin === true) {
        setMenu("Stats");
      } else {
        setMenu("Submit Info");
      }

    }
  }, [player]);


  return (
    <div className='nav-links'>
      <NavLink
        to={"/"}
        className={({ isActive }) => {
          return isActive ? 'nav-link active' : 'nav-link';
        }}
        end
      >
        <span className='icon'><MdQueryStats /></span>
        {menu}
      </NavLink>

    </div>
  );
};
export default NavLinks;
