import StatItem from "./StatItem";
import Wrapper from "../assets/wrappers/StatsContainer";
import { useSelector, useDispatch } from 'react-redux';
import LoadingWrapper from "../assets/wrappers/LoadingWrapper";
import Loading from "../components/Loading";

const StatsContainer = () => {

  const { data, loading, error} = useSelector((state) => state.stat);

  if (loading) {
    return (
      <LoadingWrapper className="full-page">
        <Loading />
      </LoadingWrapper>
    )
  }

  return (
    <Wrapper>
      {data.map((item, index) => (
        <StatItem key={index} {...item} />
      ))}
    </Wrapper>
  );
};
export default StatsContainer;
