import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./Login";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const RoleBasedRedirect = () => {
  const location = useLocation();
  const { player } = useSelector((store) => store.player);
  // console.log("RoleBasedRedirect player:", player, location.pathname);

  if (player === null) {
    if (location.pathname !== "/" && location.pathname !== "/login") {
      toast.error("Login is required.");
    }
    return <Navigate to="/login" replace state={{ from: location }}/>;
  }

  return <Outlet />;


};

export default RoleBasedRedirect;