import { useSelector } from "react-redux";
import Wrapper from "../assets/wrappers/StatItem";
import { useNavigate } from "react-router-dom";

const StatItem = ({ player, totalAnswers, correctAnswers, wrongAnswers }) => {

  const navigate = useNavigate();
  
  const handleClicked = () => {
    navigate(`/statplayer/${player.registNum}`);
  };

  return (
    <Wrapper color={"#1d4ed8"}>
      <div className="cursor-pointer" onClick={handleClicked}>
        <div className="parent">
          <div className="name">{player.registNum}</div>
          <div className="scored">{player.team}</div>
        </div>
        <hr />
        <header className="mt-5">
          <span className="count">Score: {player.score}</span>
          <span className="correct">Correct: {correctAnswers}</span>
          <span className="wrong">Wrong: {wrongAnswers}</span>
        </header>
      </div>
    </Wrapper>
  );
};
export default StatItem;
