import { useEffect, useState, useRef } from "react";
import Wrapper from "../assets/wrappers/LandingPage";
import { FormRow, FormRowSelect, Logo, Plogo } from "../components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { loginPlayer, registerPlayer, setIsLoginFailed } from "../features/player/playerSlice";

const initialState = {
  team: "",
  password: "",
  quizNum: "",
  isMember: true,
};

const Login = () => {
  const [values, setValues] = useState(initialState);
  const { player, isLoading, isLoginFailed } = useSelector((store) => store.player);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("onsubmit:", values);
    const { team, password } = values;
    if (!team || !password) {
      toast.error("Please fill out all fields");
      return;
    }

    dispatch(loginPlayer({ team: team, password: password }));
  };

  useEffect(() => {
    if (player === null) {
      console.log("player is null");
      return;
    }

    if (from.includes("/quiz/")) {
      navigate(from);
    } else {
      if (player.isAdmin) {
        console.log("navigate to stats");
        navigate(`/stats`);
      } else {
        navigate(`/playerpage`);
      }
    }
  }, [player, location.pathname]);

  useEffect(() => {
    console.log("location:", location);
  }, [location]);

  return (
    <Wrapper className="full-page">
      <Plogo />
      <form className="form" onSubmit={onSubmit}>
        {/*<Logo />*/}
        <div className="logo">P&G PEAKathon 2024</div>
        <hr />
        <h5>Login</h5>
        {/* team field */}
        <FormRow
          type="team"
          name="team"
          labelText={"ID"}
          value={values.team}
          handleChange={handleChange}
        />
        <FormRow
          type="password"
          name="password"
          value={values.password}
          handleChange={handleChange}
        />
        <button type="submit" className="btn btn-block" disabled={isLoading}>
          {isLoading ? "loading..." : "submit"}
        </button>
      </form>

      {isLoginFailed && (
        <aside className="modal-container">
          <div className="modal">
            <h6>Please enter your ID and password correctly.</h6>
            <h6>Your ID is your Candidate ID, and your password is your full name.</h6>
            <h6>Make sure the password matches exactly, including uppercase and lowercase letters, spaces, and any special characters.</h6>
            <div className="btn-container">
              <button
                type="button"
                className="modal-btn confirm-btn"
                onClick={() => {
                  dispatch(setIsLoginFailed(false));
                }}
              >
                Check
              </button>
            </div>
          </div>
        </aside>
      )}
    </Wrapper>
  );
};

export default Login;
