import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import teamSlice from "./features/team/teamSlice";
import allTeamsSlice from "./features/allTeams/allTeamsSlice";
import playerSlice from "./features/player/playerSlice";
import modalSlice from "./features/modal/modalSlice";
import testUser from "./features/test/testUser";
import statSlice from "./features/stat/statSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    player: playerSlice,
    team: teamSlice,
    allTeams: allTeamsSlice,
    modal: modalSlice,
    testUser: testUser,
    stat: statSlice,
  },
});
