import axios from "axios";
import { clearStore } from "../features/user/userSlice";
import { getUserFromLocalStorage } from "./localStorage";

// const customFetch = axios.create({
//   baseURL: "http://localhost:80/api/v1",
// });

// const customFetch = axios.create({
//  baseURL: "/api/v1",
// });

const customFetch = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers : {
    "Cache-Control": "no-cache",
  }
});

customFetch.interceptors.request.use((config) => {
  const user = getUserFromLocalStorage();
  if (user) {
    config.headers["Authorization"] = `Bearer ${user.token}`;
  }
  return config;
});

export const checkForUnauthorizedResponse = (error, thunkAPI) => {
  if (error.response.status === 401) {
    thunkAPI.dispatch(clearStore());
    return thunkAPI.rejectWithValue("Unauthorized! Logging Out...");
  }
  return thunkAPI.rejectWithValue(error.response.data.msg);
};

export default customFetch;
