import { Outlet } from 'react-router-dom';
import { BigSidebar, Navbar } from '../../components';
import Wrapper from '../../assets/wrappers/SharedLayout';
import PlayerPage from '../PlayerPage';



const PlayerLayout = () => {

  return (
    <Wrapper>
      <main className="dashboard">
        <BigSidebar />
        <div>
          <Navbar title={"My page"} />
          <div className="dashboard-page">
            <PlayerPage />
          </div>
        </div>
      </main>
    </Wrapper>
  );
};
export default PlayerLayout;
