import styled from "styled-components";
import background from "../images/bg_14.PNG";

const Wrapper = styled.section`
  display: grid;
  align-items: center;
  background-image: url(${background}); /* 배경 이미지 추가 */
  background-size: cover; /* 배경 이미지가 컨테이너를 꽉 채우도록 설정 */
  background-position: center; /* 배경 이미지가 중앙에 위치하도록 설정 */
  .logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 0rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--primary-700);
  }
  .pLogo {
    position: absolute; /* 절대 위치 설정 */
    top: 0; /* 상단에서 0px의 위치 */
    left: 0; /* 왼쪽에서 0px의 위치 */
    margin-top: 30px; /* 로고의 상단 여백 설정 */
    margin-left: 40px; /* 로고의 왼쪽 여백 설정 */
    width: 250px; /* 로고의 너비 설정 */
    height: auto; /* 높이를 자동으로 설정하여 비율 유지 */
  }
  .form {
    max-width: 400px;
  }
  .form-label {
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 5px;
  }
  h5 {
    text-align: center;
    font-weight: 500;
    color: var(--grey-200);
  }
  hr {
    background-color: var(--grey-200);
    height: 1px;
    border: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .btn {
    margin-top: 1rem;
    background-color: var(--primary-1000);
  }
  .btn:hover {
    background-color: var(--primary-700);
  }
  p {
    margin: 0;
    margin-top: 1rem;
    text-align: center;
  }
  .member-btn {
    background: transparent;
    border: transparent;
    color: var(--primary-500);
    cursor: pointer;
    letter-spacing: var(--letterSpacing);
  }
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal {
    background: var(--clr-white);
    width: 80vw;
    max-width: 720px;
    border-radius: var(--radius);
    padding: 2rem 1rem;
    text-align: center;
  }
  .modal h6 {
    font-size: 20px;
    line-height: 1.8;
    margin-bottom: 0;
    font-weight: 600;
    margin-top: 0;
    text-transform: none;
  }

  .modal .confirm-btn {
    margin-top: 3rem;
    font-weight: 700;
    background-color: var(--primary-700);
    height: 50px;
    width: 150px;
    font-size: 1.5em;
  }
  .modal .confirm-btn:hover {
    background-color: var(--primary-1000);
  }
  .modal .clear-btn {
    margin-top: 1.5rem;
    font-weight: 700;
    height: 50px;
    width: 150px;
    font-size: 1.5em;
    background: var(--grey-500);
  }

  .modal .clear-btn:hover {
    background: var(--grey-700);
  }
`;
export default Wrapper;
