import Wrapper from "../assets/wrappers/Navbar";
import { FaAlignLeft } from "react-icons/fa";
import Logo from "./Logo";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../features/user/userSlice";
import { logoutPlayer } from "../features/player/playerSlice";
import { toast } from "react-toastify";
import customFetch from "../utils/axios";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { useState } from "react";

const Navbar = ({ title }) => {
  const dispatch = useDispatch();
  const { player } = useSelector((store) => store.player);
  const navigate = useNavigate();
  const [excelLoading, setExcelLoading] = useState(false);

  const toggle = () => {
    dispatch(toggleSidebar());
  };

  const handleIndiaExcelDownload = async () => {
    console.log("Excel Download");
    try {
      setExcelLoading(true);
      let playerData = await getPlayerScoresByCountry();
      playerData = playerData
      .filter((item) => item.player.country === "India")
      .map((item) => {
        const itemPlayer = item.player; // 올바른 위치로 이동
        return {
          campus: itemPlayer.campus,
          country: itemPlayer.country,
          sheetName: itemPlayer.sheetName,
          "Register Number": itemPlayer.registNum,
          ID: itemPlayer.team,
          "Number of submissions": itemPlayer.quizAttempts,
          "Number of correct answers": item.correctAnswers,
          "Number of wrong answers": item.wrongAnswers,
          "Score of Player": itemPlayer.score,
          "Answer to quiz 21": item.quiz21Answer || "N/A",
          "First login time": itemPlayer.firstLoginTime || "N/A",
          "Final quiz submission time":
            itemPlayer.finalQuizSubmissionTime || "N/A",
        };
      });

      console.log(`playerData:`, playerData);

      // 워크북 생성
      const workbook = XLSX.utils.book_new();

      // playerData를 country별로 그룹화
      const groupedByCountry = playerData.reduce((acc, item) => {
        acc[item.campus] = acc[item.campus] || [];
        acc[item.campus].push(item);
        return acc;
      }, {});

      console.log(`groupedByCountry:`, groupedByCountry);

      // 각 country별로 워크시트 생성 및 워크북에 추가
      Object.keys(groupedByCountry).forEach((campus) => {
        // sheetName을 사용하여 시트 이름을 설정하고, 실제 데이터에는 포함되지 않도록 처리
        const sheetName = groupedByCountry[campus][0].sheetName;

        // 데이터를 복사하여 sheetName을 제외한 나머지 필드만 포함
        const dataWithoutSheetName = groupedByCountry[campus].map(
          ({ sheetName, ...rest }) => rest
        );

        const worksheet = XLSX.utils.json_to_sheet(dataWithoutSheetName);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      });

      // 워크북을 바이너리 형식으로 작성
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Blob 생성
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // 파일 저장
      saveAs(blob, "Participant Statistics(India).xlsx");
    } catch (error) {
      toast.error("Failed to download excel file");
      console.log(`error:`, error);
    } finally {
      setExcelLoading(false);
    }
  };

  const handleAMAExcelDownload = async () => {
    console.log("Excel Download");
    try {
      setExcelLoading(true);
      let playerData = await getPlayerScoresByCountry();
      playerData = playerData
      .filter((item) => item.player.campus == "APAC" || item.player.campus == "MEA")
      .map((item) => {
        const itemPlayer = item.player; // 올바른 위치로 이동
        return {
          region: itemPlayer.campus,
          country: itemPlayer.country,
          sheetName: itemPlayer.sheetName,
          "Register Number": itemPlayer.registNum,
          ID: itemPlayer.team,
          "Number of submissions": itemPlayer.quizAttempts,
          "Number of correct answers": item.correctAnswers,
          "Number of wrong answers": item.wrongAnswers,
          "Score of Player": itemPlayer.score,
          "Answer to quiz 21": item.quiz21Answer || "N/A",
          "First login time": itemPlayer.firstLoginTime || "N/A",
          "Final quiz submission time":
            itemPlayer.finalQuizSubmissionTime || "N/A",
        };
      });

      console.log(`playerData:`, playerData);

      // 워크북 생성
      const workbook = XLSX.utils.book_new();

      // playerData를 country별로 그룹화
      const groupedByCountry = playerData.reduce((acc, item) => {
        acc[item.country] = acc[item.country] || [];
        acc[item.country].push(item);
        return acc;
      }, {});

      console.log(`groupedByCountry:`, groupedByCountry);

      // 각 country별로 워크시트 생성 및 워크북에 추가
      Object.keys(groupedByCountry).forEach((country) => {
        // sheetName을 사용하여 시트 이름을 설정하고, 실제 데이터에는 포함되지 않도록 처리
        const sheetName = groupedByCountry[country][0].country;

        // 데이터를 복사하여 sheetName을 제외한 나머지 필드만 포함
        const dataWithoutSheetName = groupedByCountry[country].map(
          ({ sheetName, ...rest }) => rest
        );

        const worksheet = XLSX.utils.json_to_sheet(dataWithoutSheetName);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      });

      // 워크북을 바이너리 형식으로 작성
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Blob 생성
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // 파일 저장
      saveAs(blob, "Participant Statistics(AMA).xlsx");
    } catch (error) {
      toast.error("Failed to download excel file");
      console.log(`error:`, error);
    } finally {
      setExcelLoading(false);
    }
  };

  const getPlayerScoresByCountry = async () => {
    try {
      const timestamp = new Date().getTime();
      const response = await customFetch.post(`/auth/getPlayerScoresByCountry`);
      const { data } = response;
      console.log(`getPlayerScoresByCountry data:`, data);
      return data.data;
    } catch (error) {
      toast.error(error.response.data.error);
      console.error(error);
    }
  };

  const handleNavigationAndReload = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      {excelLoading && (
        <FadeLoader
          cssOverride={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "10",
          }}
          color="#1d4ed8"
          width={4}
        ></FadeLoader>
      )}
      <Wrapper>
        <div className="nav-center">
          <button type="button" className="toggle-btn" onClick={toggle}>
            <FaAlignLeft />
          </button>
          <div className="title-container">
            <div
              className=" cursor-pointer"
              onClick={handleNavigationAndReload}
            >
              <Logo />
            </div>
            <div className="title">
              <div className="title-center">
                <div
                  className=" cursor-pointer"
                  onClick={handleNavigationAndReload}
                >
                  <h3 className="logo-text">{title}</h3>
                </div>
              </div>
              {!player.isAdmin && (
                <span className="logo-text">ID : {player.team}</span>
              )}
            </div>
          </div>
          <div className="btn-container">
            {player.isAdmin && (
              <>
                <button
                  type="button"
                  className="mr-4 bg-lime-500 rounded-md p-4 shadow-md text-white hover:bg-lime-600"
                  onClick={handleIndiaExcelDownload}
                >
                  Excel(India)
                </button>
                <button
                  type="button"
                  className="mr-4 bg-lime-500 rounded-md p-4 shadow-md text-white hover:bg-lime-600"
                  onClick={handleAMAExcelDownload}
                >
                  Excel(AMA)
                </button>
              </>
            )}
            <button
              type="button"
              className="btn"
              onClick={() => dispatch(logoutPlayer())}
            >
              logout
            </button>
          </div>
        </div>
      </Wrapper>
    </>
  );
};
export default Navbar;
