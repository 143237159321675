const Quiestions_New = [
  {
    quizNum: 1,
    quizType: "single_choice",
    question:
      "The naturals hair care category is growing. What could be a likely cause?",
    choices: [
      { A: "Decline of value naturals brands" },
      { B: "Consumer behavior shift towards more cosmetic haircare" },
      { C: "Herbal Essences is gaining value share within total hair care" },
      {
        D: "Trade down from Herbal Essences’ premium Bio renew range to Herbal Essences Classics",
      },
    ],
  },
  {
    quizNum: 2,
    quizType: "single_choice",
    question:
      "As a Brand Manager for Herbal Essences, which is the most critical element of a communication brief?",
    choices: [
      { A: "Brand pantone color" },
      { B: "Consumer insight" },
      { C: "Brand Celebrity information" },
      { D: "Product Technology story" },
    ],
  },
  {
    quizNum: 3,
    quizType: "single_choice",
    question:
      "As a Financial Analyst in the Herbal Essences team, you see a gradual decline in profit margin even though topline revenue is growing. What’s the likely cause?",
    choices: [
      { A: "Marketing expense is declining" },
      { B: "Promo efficiency is increasing" },
      { C: "Local currency is appreciating vs. USD" },
      { D: "Fixed costs in the manufacturing plant are increasing" },
    ],
  },
  {
    quizNum: 4,
    quizType: "single_choice",
    question:
      "A retailer has very small shelf space. He notices that almost all his sales come from Herbal Essences Bio Renew Argan Oil variant. What should he consider doing?",
    choices: [
      {
        A: "Give Bio Renew Argan oil the space it deserves based on volume share",
      },
      {
        B: "Give Bio Renew Argan oil the space it deserves based on value share",
      },
      { C: "Remove all other variants from the shelf." },
      { D: "Keep the shelf the same" },
    ],
  },
  {
    quizNum: 5,
    quizType: "single_choice",
    question:
      "You are choosing a brand ambassador for Herbal Essences, which of these following will make you reject a celebrity as a potential candidate?",
    choices: [
      { A: "She has her own skincare brand" },
      { B: "She is known to frequently color her hair" },
      { C: "She is known for endorsing professional salon products" },
      { D: "She frequently showcases her luxury bags on social media" },
    ],
  },
  {
    quizNum: 6,
    quizType: "single_choice",
    question:
      "You are the supply planner for Herbal Essences in your country. The shampoos & conditioners in the warehouse are going to expire in 3 months. You are unlikely to get a new shipment before the current stocks expire. You must serve the market demand while managing the tough supply situation. Which of the following actions would be the most appropriate for you to take?",
    choices: [
      {
        A: "Inform retailers that there will be no new supply in the next 6 months, until fresh stocks arrive.",
      },
      {
        B: "Work with marketing and sales to create a deep promotion to sell the stock as quickly as possible.",
      },
      {
        C: "Remove the codes for these products from the SAP ordering system, to remove any chance of orders being placed.",
      },
      { D: "Allow normal demand to continue until the day of expiry." },
    ],
  },
  {
    quizNum: 7,
    quizType: "single_choice",
    question:
      "You are the brand manager for Herbal Essences. Your legal team tells you that “ultimate smooth hair” is a high-risk claim that may be challenged by regulatory bodies if used in marketing material. However, your claim test data shows that users love this claim, and it will lead to significant sales uplift. What will be your likely action?",
    choices: [
      {
        A: "Use the claim, even though the risk is high. If challenged in courts, I would remove it.",
      },
      {
        B: "I will use the claim that qualified the 2nd highest in the claims test.",
      },
      {
        C: "Understand why the claim qualified the highest and what barrier it was addressing. Then try rewording it.",
      },
      {
        D: "The claim is absolute because of the word ‘ultimate’. I will change that word and resubmit for claims test.",
      },
    ],
  },
  {
    quizNum: 8,
    quizType: "single_choice",
    question:
      "You are the Brand Protection resource for Herbal Essences. You are seeing a lot of ‘fake’ Herbal Essences products entering the market. It’s your job to prevent this from happening to protect the brand. Which of the following routes will give you the best results?",
    choices: [
      {
        A: "Drive to the warehouse which stores these fake products and tell them to shut down.",
      },
      { B: "Issue warning letter to stores which sell these fake products." },
      {
        C: "Inform local law enforcement to act on the stores which sell this, so that it will deter other stores.",
      },
      {
        D: "Use the global P&G Hair care network to find the ‘source’ where this is manufactured.",
      },
    ],
  },
  {
    quizNum: 9,
    quizType: "single_choice",
    question:
      "You are the Sales Strategy resource on Herbal Essences. You are analyzing the distribution data of a retailer. You notice that the retailer has been reducing the distribution on the Shampoo + Conditioner bundle packs. What would be your course of action?",
    choices: [
      {
        A: "Check if single packs are distributed and visible. Shoppers can still buy singles if they cannot find the bundle packs.",
      },
      { B: "Check if removing the bundle pack would reduce category value." },
      {
        C: "If shoppers can’t find the bundle pack in 1 retailer, they will always go to another retailer to get it. So, no action needed.",
      },
      {
        D: "Check if the loss of bundle pack volumes will impact the minimum-order-quantity from the manufacturing plant.",
      },
    ],
  },
  {
    quizNum: 10,
    quizType: "single_choice",
    question:
      "A retailer has accepted that Herbal Essences is an important naturals brand to shelve. But he is indifferent to Herbal Essences Classics and Herbal Essences Bio Renew. Is this a right approach?",
    choices: [
      { A: "No. Classics and Bio Renew have different packaging." },
      { B: "Yes. Classics and Bio Renew offer the same benefits." },
      { C: "No. Classics and Bio Renew offer different benefits." },
      {
        D: "Yes. Classics and Bio Renew both have naturals propositions. If the total brand has adequate shelf space, the approach is right.",
      },
    ],
  },
  {
    quizNum: 11,
    quizType: "single_choice",
    question:
      "A new consumer research uncovered that women in their first pregnancy are actively seeking out natural products. What would be your appropriate reaction to this trend if you worked on Herbal Essences as Brand Manager?",
    choices: [
      {
        A: "Launch an influencer campaign explaining the disadvantages of non-natural haircare.",
      },
      {
        B: "Promote Herbal Essences as a brand that’s free from harmful chemicals and full of natural ingredients.",
      },
      { C: "Do nothing. Pregnancy care is not relevant to the brand." },
      {
        D: "Do nothing. Pregnant women will naturally pick Herbal Essences as the brand of choice.",
      },
    ],
  },
  {
    quizNum: 12,
    quizType: "single_choice",
    question:
      "Consumer insight tells you that consumers don’t think Naturals hair care products can give her great looking hair. What should you do?",
    choices: [
      {
        A: "Educate that she needs to use Herbal Essences Conditioner together with Shampoo to get nice hair.",
      },
      {
        B: "Communicate that Herbal Essences is full of nourishing ingredients that gives her good hair.",
      },
      { C: "Educate that Herbal Essences is safe for colored hair." },
      { D: "Communicate that Herbal Essences is as good as a salon brand." },
    ],
  },
  {
    quizNum: 13,
    quizType: "single_choice",
    question:
      "You observe a trend of value naturals brands gaining share from Herbal Essences in market, what would you do?",
    choices: [
      { A: "Run deeper and more frequent promotions on Herbal Essences." },
      {
        B: "Launch a new campaign to communicate Herbal Essence’s superiority.",
      },
      { C: "Launch a new value-tier range within Herbal Essences." },
      { D: "Reduce the recommended selling price of Herbal Essences." },
    ],
  },
  {
    quizNum: 14,
    quizType: "single_choice",
    question:
      "You’re a brand manager for Herbal Essences in charge of the biggest product launch in 5 years. 1 week before the launch date, you’re told that there’s a quality issue on the packaging which causes 1 in 50 labels to peel off after a month. What would you do?",
    choices: [
      { A: "1 in 50 is insignificant, no intervention is needed." },
      {
        B: "Delay the launch by 1 month to screen out the defective units before shipping, while addressing the root issues in parallel.",
      },
      { C: "Postpone the launch indefinitely until the issue is fixed." },
      {
        D: "Continue with the launch and brief the customer careline on how to address potential consumer complaints.",
      },
    ],
  },
  {
    quizNum: 15,
    quizType: "single_choice",
    question:
      "You are the Analytics & Insights manager for herbal essences. User penetration for natural hair care has been declining in the past 2 years. What are the key consumer questions you would ask to address this?",
    choices: [
      { A: "How much does she spend on her hair care products?" },
      { B: "What are the unmet needs of natural hair care users?" },
      { C: "What are the ingredients she’s looking for in her hair care?" },
      { D: "How often does she use natural hair care products?" },
    ],
  },
  {
    quizNum: 16,
    quizType: "boolean",
    question:
      "True or false? Herbal Essences Bio Renew & Classics Shampoos with the same fill volume have the same manufacturing costs.",
    choices: [{ TRUE: "TRUE" }, { FALSE: "FALSE" }],
  },
  {
    quizNum: 17,
    quizType: "boolean",
    question:
      "True or false? Herbal Essences is an emerging brand, with just 2 markets contributing 80% of total brand sales in the region. Designing customized products and campaigns for each market is the best way to grow.",
    choices: [{ TRUE: "TRUE" }, { FALSE: "FALSE" }],
  },
  {
    quizNum: 18,
    quizType: "boolean",
    question:
      "True or False? The more product & price tiers Herbal Essences has, the more successful the brand will be as it’s able to address even more consumer segments.",
    choices: [{ TRUE: "TRUE" }, { FALSE: "FALSE" }],
  },
  {
    quizNum: 19,
    quizType: "sequence",
    question:
      "You are the head of the hair care business in your country. You need to decide on the priority of investment across many vectors.",
    choices: [
      { A: "Media" },
      { B: "Beauty Advisors" },
      { C: "In-store promotions" },
      { D: "Retail distribution" },
      { E: "Eye-level shelving" },
    ],
  },
  {
    quizNum: 20,
    quizType: "sequence",
    question:
      "You are the Brand Manager of Herbal Essences developing a new advertising asset.",
    choices: [
      { A: "Ingredient story" },
      { B: "Music" },
      { C: "Celebrity choice" },
      { D: "Benefit" },
      { E: "Branding" },
    ],
  },
  {
    quizNum: 21,
    quizType: "subjective",
    question: "If you had to write a letter to your future self five years from now, what would you hope to have accomplished at P&G, and why do you believe this is the right company to achieve those goals?",
  }
];

export default Quiestions_New;
