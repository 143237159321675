import styled from "styled-components";

const Wrapper = styled.article`
  padding: 2rem;
  background: var(--white);
  border-radius: var(--borderRadius);
  border-bottom: 5px solid ${(props) => props.color};

  .parent {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    align-items: flex-start;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
    font-size: 1.25rem;
  }

  .name {
    color: ${(props) => props.color};
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
  }

  .answer-wrapper {
    max-width: 70%;
    font-size: 15px;
    flex-shrink: 0; /* 요소의 크기가 줄어들지 않도록 설정 */
    flex-basis: auto; /* 기본 크기를 자동으로 설정 */
  }

  .answer-text {
    display: inline-block;
    max-width: 100%;
    white-space: normal; /* 줄바꿈이 가능하도록 설정 */
    word-wrap: break-word; /* 단어가 길어질 경우 줄바꿈 */
    word-break: break-word; /* 단어가 컨테이너 너비를 초과할 때 줄바꿈 */
    vertical-align: top;
    font-size: 15px;
  }

  .correct {
    color: var(--green-mid);
  }

  .wrong {
    color: var(--red-mid);
  }

  .unanswered {
    font-weight: 700;
    font-size: 15px;
  }
`;

export default Wrapper;
