import styled from 'styled-components'

const Wrapper = styled.section`
  display: grid;
  row-gap: 2rem;
  align-items: start; /* 자식 요소들의 높이를 개별적으로 설정 */
  grid-auto-rows: min-content; /* 자식 요소들이 콘텐츠에 따라 최소 높이를 가지도록 설정 */
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
  @media (min-width: 1120px) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
  }
`
export default Wrapper
