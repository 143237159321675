import styled from "styled-components";

const Wrapper = styled.nav`
  height: var(--nav-height);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.1);
  .logo {
    display: flex;
    align-items: center;
    width: 75%;
  }
  h3 {
    font-weight: 700;
    color: var(--primary-600);
  }
  .nav-center {
    display: flex;
    width: 90vw;
    align-items: center;
    justify-content: space-between;
  }
  .toggle-btn {
    background: transparent;
    border-color: transparent;
    font-size: 1.75rem;
    color: var(--primary-500);
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  background: var(--white);
  .btn-container {
    position: relative;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 0.5rem;
    position: relative;
    background: var(--primary-1000);
    box-shadow: var(--shadow-2);
    padding: 0.75rem;
    width: 100%;
  }
  .btn:hover {
    background: var(--primary-700);
  }
  .btn_execel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 0.5rem;
    position: relative;
    
    box-shadow: var(--shadow-2);
    padding: 0.75rem;
    width: 100%;
  }
  .btn_execel:hover {
    
  }
  .logo-text {
    display: none;
    margin: 0;
    color: var(--primary-600);
  }
  .title {
    width: 100%;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between; /* 양 끝에 자식 요소들을 배치 */
    align-items: center;
  }
  .title-center {
      flex-grow: 1; /* 가능한 모든 공간을 차지하도록 설정 */
    display: flex;
    justify-content: center; /* 중앙 정렬 */
  }
    .title-container {
    width: 100%;
}
  @media (min-width: 992px) {
    position: sticky;
    top: 0;

    .nav-center {
      width: 90%;
    }
    .logo {
      display: none;
    }
    .logo-text {
      display: block;
    }
  }
`;
export default Wrapper;
