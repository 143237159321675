import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// 플레이어만 접근할 수 있게 만드는 컴포넌트
export const ProtectedPlayer = ({ children }) => {
  const { player } = useSelector((store) => store.player);
  if (player.isAdmin === true) {
    // 플레이어가 관리자인 경우
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedPlayer;
