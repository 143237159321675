import { useState } from "react";

const QuizSelectBox = ({
  index,
  name,
  value,
  choices,
  choice,
  handleChange,
  disabled,
}) => {
  const quizKey = Object.keys(choice)[0]; // 현재 선택된 항목의 키

 
  return (
    <div className="w-full py-2">
      <label className="quiz-label flex">
        <div className="mr-5">{index}</div>
        <select
          name={name}
          id={name}
          value={value}
          onChange={handleChange}
          className="w-full quiz-select bg-white border border-gray-300 text-gray-700 py-2 px-4 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-200"
          disabled={disabled}
        >
          <option value="" className="">
            Select
          </option>
          {choices.map((itemValue, index) => {
            const itemKey = Object.keys(itemValue)[0];
            console.log(
              `QuizSelectBox option - itemKey: ${itemKey}, itemValue: ${itemValue[itemKey]}`
            ); // 확인용 콘솔 로그
            return (
              <option key={index + 1} value={itemKey}>
                {" "}
                {/* 옵션의 값으로 키를 사용 */}
                {`${itemKey} : ${itemValue[itemKey]}`}
              </option>
            );
          })}
        </select>
      </label>
    </div>
  );
};

export default QuizSelectBox;
