import { Outlet } from 'react-router-dom';
import { BigSidebar, Navbar } from '../../components';
import Wrapper from '../../assets/wrappers/SharedLayout';

const SharedLayout = ({ children }) => {

  return (
    <Wrapper>
      <main className="dashboard">
        <BigSidebar />
        <div>
          <Navbar title={"Dashboard"} />
          <div className="dashboard-page">
            {children}
          </div>
        </div>
      </main>
    </Wrapper>
  );
};
export default SharedLayout;
