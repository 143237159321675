import React, { useEffect } from "react";
import { Logo } from "../components";
import Wrapper from "../assets/wrappers/Submitted";
import { useDispatch } from "react-redux";
import { clearStorePlayer } from "../features/player/playerSlice";
import { useNavigate } from 'react-router-dom';

const SubmitPage = () => {
  const navigate = useNavigate();

  const goToPlayerPage = () => {
    navigate('/playerpage'); // 여기서 '/playerpage'는 이동하고자 하는 경로입니다.
  };

  return (
    <Wrapper className="full-page">
      <div className="form">
        <Logo />
        <hr />
        <br />
        <div className="box">
          <p className="comment">Your answer has been submitted and time stamped.</p>
          <p className="comment">Please move onto the next quiz.</p>
        </div>
        <br />
      </div>
    </Wrapper>
  );
};

export default SubmitPage;
