import NavLinks from './NavLinks';
import Logo from '../components/Logo';
import Wrapper from '../assets/wrappers/BigSidebar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const BigSidebar = () => {
  const { isSidebarOpen } = useSelector((store) => store.user);
  const navigate = useNavigate();

  const handleNavigationAndReload = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <Wrapper>
      <div
        className={
          isSidebarOpen
            ? 'sidebar-container '
            : 'sidebar-container show-sidebar'
        }
      >
        <div className='content'>
          <header className=' cursor-pointer' onClick={handleNavigationAndReload}>
            <Logo />
          </header>
          <NavLinks />

        </div>
      </div>
    </Wrapper>
  );
};
export default BigSidebar;
