import React from 'react';
import Wrapper from "../assets/wrappers/StatsContainer";
import PlayerItem from '../components/PlayerItem';
import { useEffect, useState } from 'react';    
import customFetch from '../utils/axios';
import { useSelector } from 'react-redux';

const PlayerPage = () => {
    const {player} = useSelector((store) => store.player);
    const [playerState, setPlayerState] = useState([]);

    useEffect(() => {
      if(player) {
        getPlayerState();
      }
    }, [player]);

    const getPlayerState = async () => {
        try {
          const response = await customFetch
          .post(`/teams/getSubmitInfo`, {registNum : player.registNum});
          const { data } = response;
          console.log(data);
          setPlayerState(data.allQuizzes);
        } catch (error) {
          console.error(error);
        }
      };

    return (
        <Wrapper>
            {playerState?.map((item, index) => {
              if(index === 20) {
                return;
              }

                return <PlayerItem key={index} item = {item} />;
            })}
        </Wrapper>
    );
};

export default PlayerPage;