import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// 관리자만 접근할 수 있게 만드는 컴포넌트
const ProtectedAdmin = ({ children }) => {
  const { player } = useSelector((store) => store.player);
  // console.log("ProtectedAdmin 테스트:", player);
  if (player.isAdmin !== true) {
    // 플레이어가 관리자가 아닌 경우
    toast.error("You are not authorized to access this page");
    return <Navigate to="/denied" />;
  }
  return children;
};

export default ProtectedAdmin;
