import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../features/stat/statSlice';
import { useEffect } from 'react';

const StatPagination = () => {
  const dispatch = useDispatch();
  const { page, pageSize, totalPage } = useSelector((state) => state.stat);

  const startPage = Math.floor((page - 1) / 10) * 10 + 1;
  const endPage = Math.min(startPage + 9, totalPage);

  const changePage = (newPage) => {
    dispatch(setPage(newPage));
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    for (let pageNum = startPage; pageNum <= endPage; pageNum++) {
      pageButtons.push(
        <button
          key={pageNum}
          onClick={() => changePage(pageNum)}
          disabled={pageNum === page}
          className={`px-4 py-2 border rounded-md mx-1 ${
            pageNum === page ? 'bg-blue-500 text-white' : 'bg-white text-black'
          } hover:bg-blue-400 hover:text-white`}
        >
          {pageNum}
        </button>
      );
    }
    return pageButtons;
  };


// useEffect(() => {
//     console.log("page:", page);
//     console.log("pageSize:", pageSize);
//     console.log("totalPages:", totalPage);
// }, [page, pageSize, totalPage]);

return (
    <div className="flex justify-center items-center my-4">
      {startPage > 1 && (
        <>
          <button
            onClick={() => changePage(1)}
            className="px-4 py-2 border rounded-md mx-1 bg-white text-black hover:bg-blue-400 hover:text-white"
          >
            {"<<"}
          </button>
          <button
            onClick={() => changePage(startPage - 1)}
            className="px-4 py-2 border rounded-md mx-1 bg-white text-black hover:bg-blue-400 hover:text-white"
          >
            {"<"}
          </button>
        </>
      )}
      {renderPageButtons()}
      {endPage < totalPage && (
        <>
          <button
            onClick={() => changePage(endPage + 1)}
            className="px-4 py-2 border rounded-md mx-1 bg-white text-black hover:bg-blue-400 hover:text-white"
          >
            {">"}
          </button>
          <button
            onClick={() => changePage(totalPage)}
            className="px-4 py-2 border rounded-md mx-1 bg-white text-black hover:bg-blue-400 hover:text-white"
          >
            {">>"}
          </button>
        </>
      )}
    </div>
  );
};

export default StatPagination;