import React from "react";
import StatPlayerContent from "../../components/StatPlayerContent";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import customFetch from "../../utils/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { set } from "mongoose";
const StatPlayer = () => {
  const { registNum } = useParams();
  const [playerInfo, setPlayerInfo] = useState({});
  const [playerState, setPlayerState] = useState([]);
  const [sortKey, setSortKey] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // 플레이어 수정 모달창

  const [isEditLoading, setIsEditLoading] = useState(false); // 플레이어 수정 로딩창.
  const [editPlayerInfo, setEditPlayerInfo] = useState({
    name: "",
    country: "",
    password: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    getPlayerState();
  }, [registNum, isEditLoading]);

  const handleSortKeyChange = (event) => {
    setSortKey(event.target.value);
  };

  const getPlayerState = async () => {
    try {
      const response = await customFetch.post(`/teams/getSubmitInfo`, {
        registNum,
      });
      const { data } = response;
      console.log(`data:`, response);
      setPlayerState(data.allQuizzes);
      setPlayerInfo(data.player);
      setEditPlayerInfo({
        name: data.player.team,
        campus: data.player.campus,
        country: data.player.country,
        password: data.player.password,
      });
    } catch (error) {
      toast.error(error.response.data.error);
      navigate("/stats");
      console.error(error);
    }
  };

  const handleChangePlayerInfo = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log("handleChangePlayerInfo : ", name, value);
    setEditPlayerInfo({ ...editPlayerInfo, [name]: value });
  };

  const editPlayer = async () => {
    setIsEditLoading(true);

    const fields = [
      { key: "name", message: "Please enter the name." },
      { key: "campus", message: "Please enter the campus." },
      { key: "country", message: "Please enter the country." },
      { key: "password", message: "Please enter the password." },
    ];

    for (let { key, message } of fields) {
      if (!editPlayerInfo[key]) {
        toast.error(message);
        return;
      }
    }

    try {
      const response = await customFetch.patch("/auth/updatePlayer", {
        registNum,
        team: editPlayerInfo.name,
        campus: editPlayerInfo.campus,
        country: editPlayerInfo.country,
        password: editPlayerInfo.password,
      });
      toast.success("Player edited successfully.");
      console.log("editPlayer response : ", response);
      setEditPlayerInfo(false);
      setIsEditLoading(false);
      setIsEditModalOpen(false);
    } catch (error) {
      toast.error("Failed to edit player.");
    }
  };

  return (
    <>
      <div className="mb-7 rounded-md bg-white py-3 px-5 shadow-lg">
        <div className="flex items-center justify-between mt-2 mb-7">
          <div className="font-bold text-lg">Player Info</div>
          <button
            onClick={() => {
              setIsEditModalOpen(true);
            }}
            className="mr-4 rounded-md p-4 shadow-md text-white bg-orange-400 hover:bg-orange-500"
          >
            Player Edit
          </button>
        </div>
        <div className="mb-7 flex flex-col gap-8 md:flex-row items-center bg-gray-100 p-4 rounded-lg shadow">
          <div className="text-lg font-semibold text-gray-800 mb-2 md:mb-0">
            Regist Number:{" "}
            <span className="text-blue-600">{playerInfo.registNum}</span>
          </div>
          <div className="text-lg font-semibold text-gray-800">
            Campus: <span className="text-green-600">{playerInfo.campus}</span>
          </div>
          <div className="text-lg font-semibold text-gray-800">
            Country:{" "}
            <span className="text-green-600">{playerInfo.country}</span>
          </div>
          <div className="text-lg font-semibold text-gray-800">
            ID: <span className="text-green-600">{playerInfo.team}</span>
          </div>
          <div className="text-lg font-semibold text-gray-800">
            Score: <span className="text-green-600">{playerInfo.score}</span>
          </div>
        </div>
        <div className="py-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          <SelectField
            label="Status"
            value={sortKey}
            onChange={handleSortKeyChange}
            options={[
              { value: "all", label: "All" },
              { value: "correct", label: "Correct" },
              { value: "wrong", label: "Wrong" },
              { value: "isNotSubmitted", label: "Unanswered" },
            ]}
            className="text-sm"
          />
        </div>
      </div>
      <StatPlayerContent
        playerState={playerState}
        sortKey={sortKey}
        className="mt-5"
      />
      {isEditModalOpen && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          id="my-modal"
        >
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="flex justify-between items-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Edit Player
              </h3>
              <button
                onClick={() => setIsEditModalOpen(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-2 px-7 py-3">
            <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="campus"
                >
                  Campus
                </label>
                <input
                  onChange={handleChangePlayerInfo}
                  value={editPlayerInfo.campus}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="campus"
                  type="text"
                  placeholder="Campus"
                  name="campus"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="country"
                >
                  Country
                </label>
                <input
                  onChange={handleChangePlayerInfo}
                  value={editPlayerInfo.country}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="country"
                  type="text"
                  placeholder="Country"
                  name="country"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  onChange={handleChangePlayerInfo}
                  value={editPlayerInfo.name}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder="Name"
                  name="name"
                />
              </div>
              <div className="mb-7">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  onChange={handleChangePlayerInfo}
                  value={editPlayerInfo.password}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="text"
                  placeholder="****"
                  name="password"
                />
              </div>
              <div className="items-center px-4 py-3">
                <button
                  onClick={editPlayer}
                  className="px-4 py-2 bg-orange-400 hover:bg-orange-500 text-white text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  Edit Player
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const SelectField = ({ label, value, onChange, options }) => (
  <div>
    <div>{label}</div>
    <select
      className="w-full p-2 border border-gray-300 rounded-md h-10 bg-gray-50"
      value={value}
      onChange={onChange}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default StatPlayer;
