import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createTeamThunk,
  cancelQuizThunk,
  editTeamThunk,
  getTeamSubmitInfoThunk,
} from "./teamThunk";
import { isCancel } from "axios";

const initialState = {
  isLoading: false,
  isCreateLoading: true,
  isSubmitLoading: false,
  isQuizCancelled: false,
  teamOptions: [
    "S_Peaky_Blinders",
  ],
  team: "",
  quizNumOptions: [
    "1",
  ],
  quizNum: "",
  statusOptions: ["correct", "wrong"],
  status: "",
  isEditing: false,
  teamId: "",
  isSubmit: false,
};

export const createTeam = createAsyncThunk("team/createTeam", createTeamThunk); // 팀 퀴즈의 답안 제출
export const cancelQuizRedux = createAsyncThunk("team/cancelQuiz", cancelQuizThunk); // 팀 퀴즈의 답안 제출 취소
export const editTeam = createAsyncThunk("team/editTeam", editTeamThunk);
export const getTeamSubmitInfo = createAsyncThunk(
  "team/getTeamSubmitInfo",
  getTeamSubmitInfoThunk
); //팀의 퀴즈 제출 정보 가져오기

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    clearValues: () => {
      return {
        ...initialState,
      };
    },
    setEditTeam: (state, { payload }) => {
      return { ...state, isEditing: true, ...payload };
    },
    resetQuizCancelled: (state) => {
      state.isQuizCancelled = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTeam.pending, (state) => {
        state.isCreateLoading = true;
        state.isLoading = true;
      })
      .addCase(createTeam.fulfilled, (state) => {
        console.log("Answered Created...!");
        state.isCreateLoading = false;
        state.isLoading = false;
        toast.success("Answered Created");
      })
      .addCase(createTeam.rejected, (state, { payload }) => {
        state.isCreateLoading = true;
        state.isLoading = true;
        toast.error(payload);
      })
      .addCase(cancelQuizRedux.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelQuizRedux.fulfilled, (state) => {
        state.isLoading = false;
        state.isQuizCancelled = true;
        toast.success("Answered Cancelled");
      })
      .addCase(cancelQuizRedux.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(editTeam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editTeam.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Answered Modified...");
      })
      .addCase(editTeam.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getTeamSubmitInfo.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(getTeamSubmitInfo.fulfilled, (state) => {
        // state.isLoading = false;
      })
      .addCase(getTeamSubmitInfo.rejected, (state, { payload }) => {
        // state.isLoading = false;
        toast.error(payload);
      });
  },
});

export const { handleChange, clearValues, setEditTeam, resetQuizCancelled } = teamSlice.actions;

export default teamSlice.reducer;
