import styled, { css } from "styled-components";
import background from "../images/bg_14.PNG";
import logo from "../images/peakathon_logo.png";

const Wrapper = styled.section`
  display: grid;
  align-items: center;
  background-image: url(${background}); /* 배경 이미지 추가 */
  background-size: cover; /* 배경 이미지가 컨테이너를 꽉 채우도록 설정 */
  background-position: center; /* 배경 이미지가 중앙에 위치하도록 설정 */
  .logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 0rem;
  }
  .pLogo {
    position: absolute; /* 절대 위치 설정 */
    top: 0; /* 상단에서 0px의 위치 */
    left: 0; /* 왼쪽에서 0px의 위치 */
    margin-top: 30px; /* 로고의 상단 여백 설정 */
    margin-left: 40px; /* 로고의 왼쪽 여백 설정 */
    width: 250px; /* 로고의 너비 설정 */
    height: auto; /* 높이를 자동으로 설정하여 비율 유지 */
  }
  ${(props) => css`
    @media (max-width: 1300px) {
      .pLogo {
        width: 200px;
        margin-top: 20px; /* 로고의 상단 여백 설정 */
        margin-left: 10px; /* 로고의 왼쪽 여백 설정 */
      }
    }
    @media (max-width: 640px) {
      .pLogo {
        width: 150px;
        left: 47%; /* 왼쪽에서 50% 위치에 배치 */
        transform: translateX(
          -50%
        ); /* X축 기준으로 -50% 만큼 이동하여 가운데 정렬 */
        margin-bottom: 20px;
      }
    }
  `}

  .form {
    max-width: 55%;
    min-width: 400px;
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .form-row {
    font-weight: 300;
  }
  .form-label {
    font-weight: 300;
    text-indent: 2px;
    font-size: 1rem;
    text-transform: none;
  }

  .loader-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
    

  .quiz-label {
    font-weight: 700;
    text-indent: 2px;
    font-size: 1rem;
    text-transform: none;
  }
  p {
    margin-top: 0px;
    max-width: 100%;
    border: 1px solid var(--grey-200);
    padding: 20px;
  }
  h4 {
    font-size: 1.25rem;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 700;
  }
  hr {
    background-color: var(--grey-200);
    margin-bottom: 10px;
    height: 1px;
    border: 0;
  }
  .btn {
    margin-top: 2rem;
    font-weight: 700;
    background-color: var(--primary-1000);
  }
  .btn:hover {
    background-color: var(--primary-700);
  }
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal {
    background: var(--clr-white);
    width: 80vw;
    max-width: 600px;
    border-radius: var(--radius);
    padding: 2rem 1rem;
    text-align: center;
  }
  .modal h4 {
    line-height: 1.5;
    margin-bottom: 0;
    font-weight: 600;
    margin-top: 0;
    text-transform: none;
  }

  .modal .confirm-btn {
    margin-top: 1.5rem;
    font-weight: 700;
    background-color: var(--primary-700);
    height: 50px;
    width: 150px;
    font-size: 1.5em;
  }
  .modal .confirm-btn:hover {
    background-color: var(--primary-1000);
  }
  .modal .clear-btn {
    margin-top: 1.5rem;
    font-weight: 700;
    height: 50px;
    width: 150px;
    font-size: 1.5em;
    background: var(--grey-500);
  }

  .modal .clear-btn:hover {
    background: var(--grey-700);
  }
`;
export default Wrapper;
