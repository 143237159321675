import { useEffect, useState, useRef, useCallback } from "react";
import { Logo, QuizSelectBox, Plogo } from "../../components";
import Wrapper from "../../assets/wrappers/Quiz";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import QuizQuestion from "../../components/QuizQuestion";
import QuizRadioRow from "../../components/QuizRadioRow";
import QuizBooleanRow from "../../components/QuizBooleanRow";
import {
  createTeam,
  getTeamSubmitInfo,
  cancelQuizRedux,
  resetQuizCancelled,
} from "../../features/team/teamSlice";
import { closeModal, openModal } from "../../features/modal/modalSlice";
import customFetch from "../../utils/axios";
import LoadingWrapper from "../../assets/wrappers/LoadingWrapper";
import Loading from "../../components/Loading";
import { useLocation } from "react-router-dom";
import { FadeLoader } from "react-spinners";

import Quiestions_New from "../../assets/quizzes/Quiestions_New";
import QuizSubjective from "../../components/QuizSubjective";
import e from "cors";

const initialState = {
  status: "",
  checked: "",
  Answer1: "",
  Answer2: "",
  Answer3: "",
  Answer4: "",
  Answer5: "",
  teamId: "",
};

const Quiz = () => {
  const [values, setValues] = useState(initialState);
  const { player } = useSelector((store) => store.player);
  const { isCreateLoading, isQuizCancelled, isLoading } = useSelector(
    (store) => store.team
  ); // 팀이 답안 제출 중인지 여부
  const { quizNum } = useParams();
  const { isOpen } = useSelector((store) => store.modal);

  const [isCheckSubmittedLoading, setIsCheckSubmittedLoading] = useState(true); // 팀 제출 여부 확인 중인지 여부
  const [isTeamSubmitted, setIsTeamSubmitted] = useState(null); // 팀이 이미 제출했는지 여부

  const startTimeRef = useRef(null);

  const [question, setQuestion] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (quizNum < 1 || Quiestions_New.length < quizNum) {
      toast.error("No such quiz exists. Please try again.");
      navigate("/");
    } else {
      setQuestion(Quiestions_New[quizNum - 1]);
    }
  }, [quizNum]);

  // 플레이어 정보가 변경되면 팀 제출 여부 확인
  useEffect(() => {
    if (player) {
      checkTeamSubmitted();
    }
  }, [player]);

  // 제출이 완료되면 submitpage로 이동
  useEffect(() => {
    console.log("isCreateLoding", isCreateLoading);

    if (isCreateLoading === false) {
      navigate("/submitpage");
    }
  }, [isCreateLoading]);

  useEffect(() => {
    if (isQuizCancelled === true) {
      console.log(`isCanceled`, isQuizCancelled);
      dispatch(resetQuizCancelled());
      setValues(initialState);
      checkTeamSubmitted();
    }
  }, [isQuizCancelled, dispatch]);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 타이머 시작
    startTimeRef.current = new Date();

    // return () => {
    //   window.removeEventListener("beforeunload", sendQuizSpentTime);
    // };
  }, []);

  useEffect(() => {
    if (isTeamSubmitted === false) {
      // 팀이 아직 제출하지 않은 경우에만 이벤트 추가
      // window.addEventListener("beforeunload", sendQuizSpentTime);
      console.log("제출하지 않은 팀입니다.");
    } else {
      console.log("이미 제출한 팀입니다.", isTeamSubmitted);
    }
  }, [isTeamSubmitted]);

  const sendQuizSpentTime = async (event) => {
    try {
      // beforeunload 이벤트에서는 기본적으로 경고 메시지를 표시할 수 있습니다.
      // 대부분의 브라우저는 사용자 지정 메시지를 표시하지 않습니다.
      event.preventDefault();
      event.returnValue = "";

      const endTime = new Date();
      const timeSpent = (endTime - startTimeRef.current) / 1000; // seconds

      const response = await customFetch.post("/teams/plusQuizSpentTime", {
        registNum: player.registNum,
        quizNum,
        timeSpent,
        quizType: question.quizType,
      });
      // Handle the response if needed
    } catch (error) {
      // Handle the error if needed
    }
  };

  const handleChange = (e) => {
    console.log(`e.target`, e.target.name, e.target.value);
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const selectChange = (e) => {
    setValues({ ...values, checked: e.target.value });
  };

  // 이미 제출한 팀인지 확인하는 함수
  const checkTeamSubmitted = async () => {
    const registNum = player.registNum;
    setIsCheckSubmittedLoading(true); // 로딩 시작

    try {
      const response = await customFetch.post(`/teams/checkSubmission`, {
        registNum,
        quizNum,
      });
      const responseData = response.data;

      if (responseData && typeof responseData.isSubmission !== "undefined") {
        // 응답이 예상대로 온 경우

        if (responseData.isSubmission) {
          // 팀이 이미 제출한 경우

          setIsTeamSubmitted(true); // 이미 제출한 팀으로 설정

          // 이후에 제출 버튼 비활성화
          // 그리고 정답관련 정보를 가져온 다음에 화면에 표시
          handleAnswerDisplay(responseData.content);
        } else {
          // 팀이 아직 제출하지 않은 경우

          setIsTeamSubmitted(false); // 아직 제출하지 않은 팀으로 설정
        }

        setIsCheckSubmittedLoading(false); // 로딩 종료
      } else {
        // 응답 형식이 예상과 다른 경우
        toast.error("Unexpected response format. Please try again.");
      }
    } catch (error) {
      // 에러 발생 시
      toast.error("Error checking team submission. Please try again.");
    }
  };

  // 정답 정보 처리를 위한 메서드
  const handleAnswerDisplay = (content) => {
    console.log("content:", content); // 제출한 퀴즈 정보
    console.log(
      "content.submittedAnswer, quizType",
      content.submittedAnswer,
      content.quizType
    );

    const submittedAnswers = content.submittedAnswer;
    console.log("submittedAnswers:", submittedAnswers);

    // 객관식의 경우 checked에 값 설정, 주관식의 경우 Answer1~5에 값 설정
    if (
      content.quizType === "single_choice" ||
      content.quizType === "boolean"
    ) {
      setValues({ ...values, checked: submittedAnswers[0] });
    } else if (content.quizType === "sequence") {
      setValues({
        ...values,
        Answer1: submittedAnswers[0],
        Answer2: submittedAnswers[1],
        Answer3: submittedAnswers[2],
        Answer4: submittedAnswers[3],
        Answer5: submittedAnswers[4],
      });
    } else if(content.quizType === "subjective") {
      setValues({ ...values, Answer1: submittedAnswers[0] });
    }
  };

  const getFilteredChoices = (index) => {
    const selectedAnswers = [
      values.Answer1,
      values.Answer2,
      values.Answer3,
      values.Answer4,
      values.Answer5,
    ].filter((answer, idx) => answer !== "" && idx !== index); // 빈 값과 현재 인덱스를 제외
    return question.choices
      ? question.choices.filter(
          (choice) => !selectedAnswers.includes(Object.keys(choice)[0])
        )
      : [];
  };

  //퀴즈 제출
  const submitAnswer = () => {
    console.log("submitAnswer 시작:", values);

    if (!player) {
      toast.error("Logged in team is not valid. Try again");
      return;
    }
    if (
      Quiestions_New[quizNum - 1].quizType === "single_choice" ||
      Quiestions_New[quizNum - 1].quizType === "boolean"
    ) {
      if (!values.checked) {
        toast.error("Please choose the answer");
        return;
      }
    } else if (Quiestions_New[quizNum - 1].quizType === "sequence") {
      if (
        !values.Answer1 ||
        !values.Answer2 ||
        !values.Answer3 ||
        !values.Answer4 ||
        !values.Answer5
      ) {
        toast.error("Please fill out all fields");
        return;
      }
    } else if (Quiestions_New[quizNum - 1].quizType === "subjective") {
      if (!values.Answer1) {
        toast.error("Please fill out all fields");
        return;
      }
    }

    let submittedAnswer = [];
    const quizType = question.quizType;

    if (quizType === "single_choice" || quizType === "boolean") {
      // 객관식
      submittedAnswer = [values.checked];
    } else if (quizType === "sequence") {
      // 순서 문제
      submittedAnswer = [
        values.Answer1,
        values.Answer2,
        values.Answer3,
        values.Answer4,
        values.Answer5,
      ];
    } else if (quizType === "subjective") {
      // 주관식
      submittedAnswer = [values.Answer1];
    }

    const team = player.team;
    const registNum = player.registNum;

    // 팀 제출 정보 업데이트
    const endTime = new Date();
    const timeSpent = (endTime - startTimeRef.current) / 1000; // seconds
    dispatch(
      createTeam({
        team,
        registNum,
        quizType,
        quizNum,
        submittedAnswer,
        timeSpent,
      })
    );
    //window.removeEventListener("beforeunload", sendQuizSpentTime); // 이벤트 제거
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  // 퀴즈 취소 요청 (관리자)
  const cancelQuiz = useCallback(() => {
    console.log(`cancelQuiz`);
    const registNum = player.registNum;
    dispatch(cancelQuizRedux({ registNum, quizNum }));
  }, [dispatch, player.registNum, quizNum]);

  if (isCheckSubmittedLoading) {
    return (
      <LoadingWrapper className="full-page">
        <Loading />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper className="full-page">
      <Plogo />
      <div className="form">
        {isLoading && (
          <FadeLoader
            cssOverride={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
            }}
            color="#1d4ed8"
            width={4}
          ></FadeLoader>
        )}
        {player.isAdmin === true ? (
          <>
            <div className="flex flex-col items-end">
              <button
                onClick={cancelQuiz}
                className=" text-sm w-[200px] px-4 py-2 bg-orange-400 hover:bg-orange-500 text-white text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
              >
                Refresh (only admin)
              </button>
              <div
                style={{ textAlign: "center", marginBottom: "1rem" }}
                className="  self-center"
              >
                <div
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "700",
                    color: "var(--primary-700)",
                    marginBottom: "0.5rem",
                  }}
                >
                  P&G PEAKathon 2024
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{ textAlign: "center", marginBottom: "1rem" }}
              className=""
            >
              <div
                style={{
                  fontSize: "1.25rem",
                  fontWeight: "700",
                  color: "var(--primary-700)",
                  marginBottom: "0.5rem",
                }}
              >
                P&G PEAKathon 2024
              </div>
            </div>
          </>
        )}

        <hr />
        <QuizQuestion type="question" quizNum={quizNum} />
        {question.quizType === "single_choice"
          ? question.choices.map((choice, index) => {
              return (
                <QuizRadioRow
                  key={index}
                  type="radio"
                  quizNum={quizNum}
                  choice={choice}
                  handleChange={selectChange}
                  checked={values.checked}
                  disabled={isTeamSubmitted}
                />
              );
            })
          : question.quizType === "sequence"
          ? question.choices.map((choice, index) => {
              const filteredChoices = getFilteredChoices(index);
              return (
                <QuizSelectBox
                  key={index}
                  name={`Answer${index + 1}`}
                  index={index + 1}
                  quizNum={quizNum}
                  value={`${values[`Answer${index + 1}`]}`}
                  choices={filteredChoices}
                  choice={choice}
                  handleChange={handleChange}
                  disabled={isTeamSubmitted}
                />
              );
            })
          : question.quizType === "boolean" ?
            question.choices.map((choice, index) => {
              return (
                <QuizBooleanRow
                  key={index}
                  type="radio"
                  quizNum={quizNum}
                  choice={choice}
                  handleChange={selectChange}
                  checked={values.checked}
                  disabled={isTeamSubmitted}
                />
              );
            })
            :question.quizType === "subjective" && (
              <QuizSubjective name={"Answer1"} value={values.Answer1} handleChange={handleChange} disabled={isTeamSubmitted} />
            )
          }

        <div className="mt-7"></div>
        {isTeamSubmitted === false && isLoading === false  ? (
          <button
            type="btn"
            className="w-8/12 px-10 btn btn-block submit-btn"
            disabled={isTeamSubmitted}
            onClick={() => {
              //객관식의 경우 선택한 값이 있으면 모달창을 띄움
              if (
                Quiestions_New[quizNum - 1].quizType === "single_choice" ||
                Quiestions_New[quizNum - 1].quizType === "boolean"
              ) {
                if (values.checked === "") {
                  toast.error("Please choose the answer");
                  return;
                }
                dispatch(openModal());
              } else if (Quiestions_New[quizNum - 1].quizType === "sequence") {
                if (
                  values.Answer1 === "" ||
                  values.Answer2 === "" ||
                  values.Answer3 === "" ||
                  values.Answer4 === "" ||
                  values.Answer5 === ""
                ) {
                  toast.error("Please fill out all fields");
                  return;
                }
                dispatch(openModal());
              } else if (Quiestions_New[quizNum - 1].quizType === "subjective") {
                if (values.Answer1 === "") {
                  toast.error("Please provide an answer.");
                  return;
                }
                dispatch(openModal());
              }
            }}
          >
            submit
          </button>
        ) : (
          <p className="bg-red-100 text-red-800 text-center font-semibold py-2 px-4 rounded-lg shadow">
            You have already submitted this quiz!
          </p>
        )}
      </div>

      {isOpen && (
        <aside className="modal-container">
          <div className="modal">
            <h4>Each player is allowed to submit only once.</h4>
            <h4>You will not be able to go back and</h4>
            <h4>edit/check your response.</h4>
            <h4>Are you sure to proceed and submit?</h4>
            <div className="btn-container">
              <button
                type="button"
                className="modal-btn confirm-btn"
                onClick={() => {
                  submitAnswer();
                  dispatch(closeModal());
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="modal-btn clear-btn"
                onClick={() => {
                  dispatch(closeModal());
                }}
              >
                No
              </button>
            </div>
          </div>
        </aside>
      )}
    </Wrapper>
  );
};
export default Quiz;
