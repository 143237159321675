import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import customFetch from "../../utils/axios";

const initialState = {
  data: [],
  loading: false,
  error: null,
  page: 1,
  pageSize: 12,
  totalPage: 0,
  sortKey: 'registNum',
  sortOrder: 'asc',
  searchQuery: '',
};

export const fetchData = createAsyncThunk(
  'stat/getPlayerScores',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState().stat;
    const timestamp = new Date().getTime(); // 현재 시간을 밀리초로 표현
    const { page, pageSize, sortKey, sortOrder, searchQuery } = state;
    try {
      const response = await customFetch.post('/auth/getPlayerScores', 
         { page, pageSize, sortKey, sortOrder, searchQuery, timestamp },
      );
      console.log(`response:`, response);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

const statSlice = createSlice({
  name: 'stat',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSortKey: (state, action) => {
      state.sortKey = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        
        state.data = action.payload.data;
        state.totalPage = action.payload.totalPages;

      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An unknown error occurred';
        toast.error(state.error);
      });
  },
});

export const {
  setPage,
  setPageSize,
  setSortKey,
  setSortOrder,
  setSearchQuery,
} = statSlice.actions;

export default statSlice.reducer;
