import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setPage,
  setPageSize,
  setSortKey as setSortKeyRedux,
  setSortOrder as setSortOrderRedux,
  setSearchQuery as setSearchQueryRedux,
  fetchData,
} from "../features/stat/statSlice";
import customFetch from "../utils/axios";
import { toast } from "react-toastify";

const StatSearch = () => {
  const dispatch = useDispatch();
  const [sortKey, setSortKey] = useState("");
  const [searchText, setSearchText] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // 플레이어 추가 모달창
  const [addPlayerInfo, setAddPlayerInfo] = useState({
    name: "",
    country: "",
    campus: "",
    password: "",
  });

  const handleSortKeyChange = (event) => {
    setSortKey(event.target.value);
    dispatch(setSortKeyRedux(event.target.value));
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
    dispatch(setSearchQueryRedux(event.target.value));
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
    dispatch(setSortOrderRedux(event.target.value));
  };

  const handleChangePlayerInfo = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log("handleChangePlayerInfo : ", name, value);
    setAddPlayerInfo({ ...addPlayerInfo, [name]: value });
  };

  const addPlayer = async () => {
    const fields = [
      { key: "name", message: "Please enter the name." },
      { key: "campus", message: "Please enter the campus." },
      { key: "country", message: "Please enter the country." },
      { key: "password", message: "Please enter the password." },
    ];

    for (let { key, message } of fields) {
      if (!addPlayerInfo[key]) {
        toast.error(message);
        return;
      }
    }

    try {
      const response = await customFetch.post("/auth/registerPlayer", {
        team: addPlayerInfo.name,
        campus: addPlayerInfo.campus,
        country: addPlayerInfo.country,
        password: addPlayerInfo.password,
      });
      toast.success("Player added successfully.");
      console.log("addPlayer response : ", response);
      setIsAddModalOpen(false);
    } catch (error) {
      toast.error("Failed to add player.");
    }
  };

  return (
    <>
      <div className="mb-7 rounded-lg bg-gray-50 shadow-lg py-5 px-6">
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Filter</h3>
          <button
            onClick={() => {
              setIsAddModalOpen(true);
            }}
            className="mr-4 rounded-md p-4 shadow-md text-white bg-orange-400 hover:bg-orange-500"
          >
            Player Add
          </button>
        </div>
        <div className="py-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <InputField
            label="Search Text"
            value={searchText}
            onChange={handleSearchTextChange}
            className="text-sm"
          />
          <SelectField
            label="Sort Key"
            value={sortKey}
            onChange={handleSortKeyChange}
            options={[
              { value: "registNum", label: "Regist Number" },
              { value: "score", label: "Score" },
            ]}
            className="text-sm"
          />
          <SelectField
            label="Sort Order"
            value={sortOrder}
            onChange={handleSortOrderChange}
            options={[
              { value: "asc", label: "Ascending" },
              { value: "desc", label: "Descending" },
            ]}
            className="text-sm"
          />
        </div>
      </div>
      {isAddModalOpen && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          id="my-modal"
        >
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="flex justify-between items-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Add New Player
              </h3>
              <button
                onClick={() => setIsAddModalOpen(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-2 px-7 py-3">
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="campus"
                >
                  Campus
                </label>
                <input
                  onChange={handleChangePlayerInfo}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="campus"
                  type="text"
                  placeholder="Campus"
                  name="campus"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="country"
                >
                  Country
                </label>
                <input
                  onChange={handleChangePlayerInfo}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="country"
                  type="text"
                  placeholder="Country"
                  name="country"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  onChange={handleChangePlayerInfo}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder="Name"
                  name="name"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  onChange={handleChangePlayerInfo}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="confirmPassword"
                  type="text"
                  placeholder="****"
                  name="password"
                />
              </div>
              <div className="items-center px-4 py-3">
                <button
                  onClick={addPlayer}
                  className="px-4 py-2 bg-orange-400 hover:bg-orange-500 text-white text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  Add Player
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const InputField = ({ label, value, onChange }) => (
  <div>
    <div>{label}</div>
    <input
      type="text"
      className="w-full p-2 border border-gray-300 rounded-md h-10 bg-gray-50"
      value={value}
      onChange={onChange}
    />
  </div>
);

const SelectField = ({ label, value, onChange, options }) => (
  <div>
    <div>{label}</div>
    <select
      className="w-full p-2 border border-gray-300 rounded-md h-10 bg-gray-50"
      value={value}
      onChange={onChange}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default StatSearch;
